<template>
  <div
    class="bg"
    :style="{ background: background }"
    v-show="isHide == '0'"
  >
    <div class="bg-mask">
      <div class="content">
        <div class="logo-img">
          <el-image
            shape="square"
            :size="size"
            :src="squareUrl"
            class="login-image"
          ></el-image>
          <!-- <div>{{cusName}}</div> -->
        </div>
        <div style="padding-right: 40px; padding-left: 40px">
          <span>
            <el-input
              placeholder="账号"
              prefix-icon="el-icon-user"
              v-model="loginName"
              @keyup.enter.native="confirmLogin"
            >
            </el-input>
          </span>
          <span
            class="login-warning"
            :style="userVisibility"
          >请输入账户名</span>
          <span>
            <el-input
              type="password"
              placeholder="密码"
              prefix-icon="el-icon-lock"
              v-model="password"
              @keyup.enter.native="confirmLogin"
            >
            </el-input>
          </span>
          <span
            class="login-warning"
            :style="psdVisibility"
          >请输入密码</span>
          <div>
            <el-checkbox v-model="checked"><span style="font-size: 13px">自动登录</span></el-checkbox>
            <el-link
              style="float: right; font-size: 13px"
              type="primary"
            >忘记密码?</el-link>
          </div>
          <div
            class="login-warning login-warning2"
            :style="npVisibility"
          >
            <i class="el-icon-warning"></i>
            <span>账号或者密码错误，请重新输入</span>
          </div>
          <div class="login-btn">
            <el-button
              style="width: 100%"
              type="primary"
              @click="confirmLogin"
            >登 录</el-button>
          </div>
          <!-- <div style="float:right;font-size:13px;">
                    <span>还没有账号?<el-link type="primary" style="float:right;font-size:13px;" @click="openRegisterDialog">立即申请</el-link></span>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import squareImg from "@/assets/images/icon.png";
import bgImg from "@/assets/images/bg/banner3.jpg";
import api from "../api/index.js";
import md5 from "js-md5";
import constants from "@/constants/Map.constants.js";
import apiIframe from "@/postMessage/api/index.js";

export default {
  data() {
    return {
      loginName: "",
      password: "",
      checked: false,
      size: "large",
      squareUrl: squareImg,
      userVisibility: "visibility:hidden",
      psdVisibility: "visibility:hidden",
      npVisibility: "visibility:hidden",
      spm: "",
      cusName: "",
      background: `url(${bgImg})`, // 背景图片
      isHide: "0",
      isProduct: 0,
      userName: "", // 路由传参 | 父级系统用户名
      positionID: "", // 路由传参 | 父级系统 ID
      isIframe: this.$store.state.PMStoreIndex.isIframe,
    };
  },
  async created() {
    sessionStorage.isHide = "0";
    this.isProduct = constants.baseURL.API.indexOf("https") > -1 ? 1 : 0;
    if (
      this.$route.query.spm == undefined ||
      this.$route.query.spm == "" ||
      this.isIframe
    ) {
      if (!!this.$route.query.loginName && !!this.$route.query.pwd) {
        if (
          !!this.$route.query.longitude &&
          !!this.$route.query.latitude &&
          !!this.$route.query.code &&
          !!this.$route.query.type
        ) {
          sessionStorage.lat = this.$route.query.latitude;
          sessionStorage.lng = this.$route.query.longitude;
          // sessionStorage.flyCode = this.$route.query.code;
          // sessionStorage.flyType = this.$route.query.type;
          this.$store.state.location.type = this.$route.query.type;
        } else {
          sessionStorage.flyCode = "noCode";
          sessionStorage.flyType = "noType";
        }

        // 存一下creator，参数上名字是 renderPositionId
        sessionStorage.renderPositionId = this.$route.query.renderPositionId;
        sessionStorage.isHide = "1"; // 隐藏顶部导航栏
        this.password = this.$route.query.pwd;
        this.loginName = this.$route.query.loginName;
        this.userName = this.$route.query.userName;
        this.positionID = this.$route.query.positionID;
        this.$store.commit("PMStoreIndex/updataMainSysCreator", {
          positionID: this.positionID,
          userName: this.userName,
        });
        this.confirmLogin();
      } else {
        if (this.isProduct == 1) {
          this.$router.push("/LinkError");
        }
        sessionStorage.isHide = "0";
      }
    } else {
      this.init();
      sessionStorage.isHide = "0";
    }
  },
  methods: {
    init() {
      this.spm = this.$route.query.spm;

      api
        .userInit({
          loginKey: this.spm,
        })
        .then((res) => {
          if (res.code == 200 && res.data != null) {
            this.squareUrl = constants.baseURL.API + res.data.logo;
            this.cusName = res.data.cusName;
            this.background = `url(${this.formatURL(res.data.backImg)})`;
          } else {
            if (this.isProduct == 1) {
              this.$router.push("/LinkError");
            }
          }
        });
    },
    formatURL(url) {
      let resUrl = "";
      if (url) {
        resUrl = constants.baseURL.API + url.replace(/\\/g, "/");
      }
      return resUrl;
    },
    //确认登录，验证账号密码
    confirmLogin() {
      this.npVisibility = "visibility:hidden";
      //账号为空
      if (this.loginName == "") {
        this.userVisibility = "visibility:visible";
      } else {
        this.userVisibility = "visibility:hidden";
      }
      //密码为空
      if (this.password == "") {
        this.psdVisibility = "visibility:visible";
      } else {
        this.psdVisibility = "visibility:hidden";
      }
      if (this.password != "" && this.loginName != "") {
        // this.$store.commit("startLoading");
        let psw = this.password;
        if (sessionStorage.isHide != "1") {
          psw = md5(this.password);
        }
        api
          .userLogin({
            loginName: this.loginName,
            pwd: psw, //
            browser: navigator.userAgent,
            ip: localStorage.ip,
            userName: this.userName,
            // positionID: this.positionID,
            positionID: sessionStorage.renderPositionId,
          })
          .then((res) => {
            this.loading = false;
            if (res.code == 200) {
              this.userName = res.data.username;
              this.loginDialogVisible = false;
              this.isLogin = true;
              sessionStorage.setItem("isLogin", 1);
              sessionStorage.setItem("userName", res.data.userName);
              sessionStorage.loginName = res.data.loginName
              sessionStorage.cusNo = res.data.cusNo;
              sessionStorage.uuid = res.data.uuid;
              sessionStorage.siteIcon = res.data.siteIcon; // 机会点
              sessionStorage.storeIcon = res.data.storeIcon; // 门店
              sessionStorage.isAdmin = res.data.isAdmin; //是否为管理员 1：是
              sessionStorage.userInfo = JSON.stringify(res.data);
              sessionStorage.token = res.data.token;
              sessionStorage.timestamp = res.data.timestamp;
              sessionStorage.spm = res.data.spm;
              if (!!res.data.color) {
                localStorage.COLOR_THEME = res.data.color;
                this.$store.state.themeColor = res.data.color;
              }
              api
                .mapStyle({
                  cusNo: res.data.cusNo,
                })
                .then((res2) => {
                  if (res2.code == 200) {
                    sessionStorage.layerList = JSON.stringify(res2.data);
                    if (!!this.$route.query.code && !!this.$route.query.type) {
                      apiIframe
                        .addressToMap({
                          type: this.$route.query.type,
                          code: this.$route.query.code,
                          cusNo: sessionStorage.cusNo,
                        })
                        .then((res3) => {
                          if (res3.code == 200) {
                            if (res3.data.length == 0) {
                              this.addressToMapFail();
                            } else {
                              // 赋值数据，地图快速定位到list指定的标记点位置
                              this.$store.state.location.data = res3.data[0];
                            }
                            // console.log('this.$route.query: ', this.$route.query);
                            this.$store.commit("updateSiteId", this.$route.query.siteId);
                            this.$store.commit("updateHoenycombId", this.$route.query.honeycombId);
                            this.$store.commit("updateSiteIdType", this.$route.query.type);
                            this.$store.commit("updateFootPrintId",
                              {
                                footPrintId: this.$route.query.footPrintId,
                                longitude: this.$route.query.longitude,
                                latitude: this.$route.query.latitude
                              }
                            );
                            this.$router.push("/home");
                          } else {
                            this.addressToMapFail();
                          }
                        })
                        .catch((err) => {
                          this.addressToMapFail();
                        });
                    } else {
                      // console.log('this.$route.query: ', this.$route.query);
                      this.$store.commit("updateSiteId", this.$route.query.siteId);
                      this.$store.commit("updateHoenycombId", this.$route.query.honeycombId);
                      this.$store.commit("updateSiteIdType", this.$route.query.type);
                      this.$store.commit("updateFootPrintId",
                        {
                          footPrintId: this.$route.query.footPrintId,
                          longitude: this.$route.query.longitude,
                          latitude: this.$route.query.latitude
                        }
                      );
                      this.$router.push("/home");
                    }
                  }
                });
            } else {
              this.npVisibility = "visibility:visible";
              this.$store.commit("closeLoading");
            }
          })
          .catch((err) => { });
      }
    },
    addressToMapFail() {
      // 如果没查询到数据，就复位，地图定位到当前城市，走正常流程
      this.$store.state.location = {
        data: {},
        type: "",
      };
      sessionStorage.lat = "";
      sessionStorage.lng = "";
      this.$message({
        message: "地图查找失败！",
        type: "warning",
        duration: 5 * 1000,
      });
    },
    openRegisterDialog() { },
  },
  mounted() {
    this.isHide = sessionStorage.isHide || "0";
  },
};
</script>
<style>
.bg {
  background: url("../assets/images/bg/banner3.jpg");
  background-size: 100% 100% !important;
  height: 100%;
  position: fixed;
  width: 100%;
}

.bg-mask {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.content {
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  width: 25%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
  left: 50%;
}

.logo-img {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 20px;
}
</style>